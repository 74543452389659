import React from "react"
import { ThemeProvider } from "styled-components"
import { library } from "@fortawesome/fontawesome-svg-core"
import {
  faFacebookSquare,
  faInstagramSquare,
  faVk,
  faOdnoklassnikiSquare,
  faTwitterSquare,
} from "@fortawesome/free-brands-svg-icons"
import {
  faTags,
  faClock,
  faChevronRight,
  faTimes,
  faWindowClose,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons"
import theme from "./theme"

library.add(
  faTags,
  faClock,
  faChevronRight,
  faFacebookSquare,
  faInstagramSquare,
  faVk,
  faOdnoklassnikiSquare,
  faTwitterSquare,
  faTimes,
  faWindowClose,
  faChevronLeft
)

const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={theme}>{element}</ThemeProvider>
)

export default wrapRootElement
