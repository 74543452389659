const BROWN_COLOR = "#cb6c32"

const colors = {
  textBrown: "#563a1c",
  gray: {
    100: "#F8F8F8",
    700: "#666",
  },
  brown: {
    300: "#d3ad68",
    700: BROWN_COLOR,
  },
}

const breakpoints = ["40em", "52em", "64em", "80em"]

const theme = {
  colors,
  breakpoints,
  fonts: {
    heading: "Roboto, sans-serif",
  },
  fontWeights: {
    heading: 400,
  },
}

export default theme
